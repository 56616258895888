import MapPosition from './mapposition';
import type Feature from 'ol/Feature';
import type Basemap from '../../models/basemap';
import type Theme from '../../models/theme';
import type BaseLayer from '../../models/layers/baselayer';
import type ThemeLayer from '../../models/layers/themelayer';
import type LayerWms from '../../models/layers/layerwms';
import type OlGeomLineString from 'ol/geom/LineString';
import type ServerOgc from '../../models/serverogc';
import type OLayerImage from 'ol/layer/Image';
import type OSourceImageWMS from 'ol/source/ImageWMS';

type GraphicalInterface = {
  helpVisible: boolean;
  drawingPanelVisible: boolean;
  printPanelVisible: boolean;
  lidarPanelVisible: boolean;
  selectionComponentVisible: boolean;
  selectionComponent: string;
  aboutVisible: boolean;
  metadataVisible: boolean;
  shareVisible: boolean;
  darkMapMode: boolean;
  darkFrontendMode: boolean;
};

export type SelectionParam = {
  _layers: LayerWms[];
  _oLayer?: OLayerImage<OSourceImageWMS>;
  selectionBox: number[];
  srid: string;
};

type Selection = {
  selectionParameters: SelectionParam[];
  selectedFeatures: Feature[];
  focusedFeatures: Feature[] | null;
  enabled: boolean;
};

type ThemesConfig = {
  _allThemes: Record<number, ThemeLayer>;
  isLoaded: boolean;
  lastSelectedTheme: ThemeLayer | null;
};

type LayersConfig = {
  layersList: BaseLayer[];
};

type TreeviewConfig = {
  advanced: boolean;
};

// Current Print state
type PrintConfig = {
  maskVisible: boolean;
  pageSize: [number, number] | null;
  format: string | null;
  scale: number | null;
  dpi: number | null;
};

// Current 3D-Globe state
type GlobeConfig = {
  display: 'none' | 'full' | 'side';
  loaded: boolean;
};

export type InfoBoxContent = {
  id: string;
  text: string;
  type: 'info' | 'warning' | 'error';
};

export type Lidar = {
  line: OlGeomLineString | null;
  drawActive: boolean;
};

export type Metadata = {
  title: string | null;
  url: string | null;
};

export default class State {
  /**
   * This class is a used as the state of the application, which will be accessed behind a javascript proxy.
   * This means that each modification made to its properties must come from outside,
   * because they have to be made through the proxy, so that the modification can be listen.
   * Therefore, this class must not contain any method which is updating a value directly
   * For example, any method doing <this.xxx = value> is forbidden here, because the modification be known from the proxy
   */

  // All themes from themes.json
  // Dictionary where the key is the id of the theme
  themes: ThemesConfig = {
    _allThemes: {},
    isLoaded: false,
    lastSelectedTheme: null
  };

  // All basemaps from themes.json
  // Dictionary where the key is the id of the basemap
  basemaps: Record<number, Basemap> = {};

  // All OCG Servers from themes.json
  // Dictionary where the key is the name of the server
  ogcServers: Record<string, ServerOgc> = {};

  // Current active basemap
  activeBasemap: Basemap | null = null;

  // Current projection
  projection!: string;

  // Current mouse coordinates
  mouseCoordinates: number[] = [];

  // Interface configuration (visible panels, ...)
  interface: GraphicalInterface = {
    helpVisible: false,
    drawingPanelVisible: false,
    printPanelVisible: false,
    lidarPanelVisible: false,
    selectionComponentVisible: false,
    selectionComponent: '',
    aboutVisible: false,
    metadataVisible: false,
    shareVisible: false,
    darkMapMode: false,
    darkFrontendMode: false
  };

  // Current language
  language: string | null = null;

  lidar: Lidar = {
    line: null,
    drawActive: false
  };

  // Is the application currently loading data ?
  loading = false;

  // Current position configuration of the map
  position: MapPosition = new MapPosition();

  // Current layers configuration
  layers: LayersConfig = {
    layersList: []
  };

  // Current Treeview state
  treeview: TreeviewConfig = {
    advanced: false
  };

  // Current Print state
  print: PrintConfig = {
    maskVisible: false,
    pageSize: null,
    format: null,
    scale: null,
    dpi: null
  };

  // Current 3D-Globe state
  globe: GlobeConfig = {
    // Possible values : ['full, 'side', 'none']
    display: 'none',
    loaded: false
  };

  // To manage selected and focused features
  selection: Selection = {
    selectionParameters: [],
    selectedFeatures: [],
    focusedFeatures: null,
    enabled: true
  };

  theme: Theme | null = null;

  infobox = {
    elements: [] as InfoBoxContent[]
  };

  metadata: Metadata = {
    title: null,
    url: null
  };

  // Indicates is the application is currently used in offline mode
  isOffline: boolean = false;

  // The State object is defined as <not extensible> by the StateManager.
  // This property can be used by third-parts components or extensions
  // to add custom attributes to the state.
  extendedState: Record<string, object> = {};
}
